html, body, #map {
  margin: 0;
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  background-color: #000;
}

:root {
 --title_colour: #DDDDDD;
 --btn_inactive: #777777;
 --btn_colour_zoom: var(--btn_inactive);   /* wird in ui.js programmatisch auf btn_colour angepasst */
 --header_height: 70px;                    /* muss zusätzlich noch in def.js angepasst werden, um FullScreenZoom für Karte zu ermitteln */
}

/* Cursors */
/* --> https://stackoverflow.com/a/18294634 und w3schools.com/cssref/pr_class_cursor.asp */
#map {
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
}


#map:active {
    cursor: grabbing;
}

.ol-popup {
     cursor: auto;
  }

/* Grid-Container */
.page {
  /* Design */
  width: 100%;
  height: 100%;
  /* border: 3px solid red; */

  /* Grid layout */
  display: grid;
  grid-template-columns: 40px 1fr 1fr;
  grid-template-rows: 8px var(--header_height) 1fr;
  grid-template-areas:
    "top top top"
    "header header header"
    "footer footer footer";
}

/* Grid-Elemente */
.top {
  grid-area: top;
  /* border: 2px solid yellow; */
  /** falls sehr platzsparendes Layout (iDiv-Logo und Linkleiste im Regenbogen), kann hier noch eine Flexbox eingesetzt werden */
}


.topRainbow {
    position: relative; /* damit können Kind-Elemente absolut gesetzt werden */
    width: 100%;
    height: 100%;
    background: url("img/topRainbow.jpg") no-repeat top left;
    background-size: 100% 100%;
    border-bottom: 1px solid darkgray;
    vertical-align: middle;
}

.header {
  grid-area: header;
  /* border: 2px solid green; */
  background-color: #222222;
  color: white;
  padding: 5px;
  border-bottom: 1px solid #555555;
}
.defaultGrid {
  /* Grid layout */
  display: grid;
  grid-template-columns: 60px minmax(350px,5fr) minmax(15px, 2fr) max-content minmax(min-content,5fr) max-content minmax(0,10px);
  grid-template-rows: 100%;
  grid-template-areas:
    "logo search pad zoomLinks zoomSlider zoomMode padRight";
  grid-gap: 10px;
}

.searchGrid {
  /* Grid layout */
  display: grid;
  grid-template-columns: 90%;
  grid-template-rows: 100%;
  grid-template-areas: "search";
  grid-gap: 10px;
}

.zoomGrid {
  /* Grid layout */
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 100%;
  grid-template-areas: "zoomMode";
  grid-gap: 10px;

  /* falls das zoomGrid in der Mitte platziert werden soll, funktioniert das mit:
    justify-items: center */
}

.buttonLabel {
  display: flex;
}

#searchCollapsed_title {
  display: none;
}

#altLinks {
  visibility: hidden;
  padding-top: 0;
  padding-left: 2px;
}

#altLinks a {
  text-decoration: none;
  color: inherit; /* Link nicht farblich kennzeichnen */
}

#pageLinks {
  z-index: 100;
}

.container_zoomMode {
  grid-area: zoomMode;
  /* border: 1px solid white; */

  /* Grid layout */
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content 40px auto;
  grid-template-areas:
    "zoomMode_title"
    "zoomMode_btn"
    "zoomMode_padding";
  justify-content: start;
}

.container_zoomMode_small {
  grid-area: zoomMode_small;
  display: none;
}

.container_zoomLinks {
  grid-area: zoomLinks;
  /* border: 1px solid white; */

  /* Grid layout */
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content 40px auto 40px;
  grid-template-areas:
    "zoomLinks_title"
    "zoomLinks_btn"
    "zoomLinks_padding";
  justify-content: start;
}

#btn_zoom_small {
  cursor: pointer;
  display: inline-block !important;
  padding-top: 2px;
  padding-bottom: 7px;
}

#zoomLinks_title_small2 {
  visibility: hidden;
}

.alternativeIcon_zoom {
  visibility: hidden;
}

#zoomMode_btn {
  grid-area: zoomMode_btn;
  margin: 0 2.5px 0 2.5px;

  /* Grid layout */
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 100%;
  grid-template-areas:
    "zoomMode_btn_options";
  justify-content: stretch;
}

/* Zurück-Buttons */
/* Schaltflächen */
.customBackButton {
  cursor: pointer;
  padding-top: 0;
  padding-top: 3px;
  padding-bottom: 5px;
}


/* Flächen im Grid */
#zoomMode_btn_back {
  grid-area: "zoomMode_btn_back";
  visibility: hidden;
}

#search_btn_back {
  grid-area: "search_back";
  visibility: hidden;
  margin-top: 20px;
}


/** Attribution box **/
.ol-attribution {
  position: absolute !important;
  bottom: 5% !important;
  right: -5px !important;
  text-align: right !important;
  bottom: .5em !important;
  right: .5em !important;
  width: 200px !important;
  color: white !important;
  background-color: rgba(50,50,50,0.8) !important;
  font-size: 20px;
  max-width: 400px !important;
}

/* eingeklappte Attribution */
.ol-collapsed {
  width: 35px !important;
}

.ol-attribution ul {
  color: var(--title_colour) !important;
  text-shadow: 0 0 1px #000 !important;
}


/** Anzeige der Attribution **/
.ol-attribution:not(.ol-collapsed) {
  background-color:rgba(255, 255, 255, .8);
  width: 20em;
  border-radius:4px;
  margin: 2px;
  font-size: 12px;
  z-index: 5;
  cursor: auto !important;
}

.ol-control button {
  background: none !important;
  cursor: pointer;
}


#hud-margin-right {
  position: absolute;
  left: auto;
}


/** UNTERSCHIEDLICHE BILDSCHIRMGRÖSSEN: HEADER **/
/* Entferne Label des Reset-Buttons */
@media only screen and (max-width: 1520px) {
  #resetLabel {
    display: none;
  }

  #resetButton {
    padding-right: 0 !important;
    padding-left: 3.3em !important;
  }

  .defaultGrid {
    grid-template-columns: 60px minmax(min-content,2fr) auto max-content minmax(min-content,1.5fr) max-content minmax(0,10px);
  }


}




/* entferne Labels der Zoombuttons und Links über der Suche*/
@media only screen and (max-width: 1340px) {
  .buttonLabel {
      display: none;
    }

  .ui.labeled.inverted.icon.customButton2 {
    padding-right:  0 !important;
    padding-bottom: 0 !important;
  }

  .defaultGrid {
    grid-template-columns: 60px minmax(min-content,2fr) auto minmax(min-content,1.5fr) max-content max-content auto;
    grid-template-areas:
      "logo search pad zoomSlider zoomMode zoomLinks padRight";
  }

  #zoomLinks_title {
    visibility: hidden;
  }

  #pageLinks {
    display: none;
  }

  #altLinks {
    visibility: visible;
    top: auto;
    bottom: 0;
    width: auto;
    /* [TODO]: Prüfen, ob es reicht,
               altLinks einmal global mit top, bottom, width zu definieren
               anstatt für jede Bildschirmgröße einzeln */
  }

    .ol-attribution {
      position: absolute !important;
      bottom: -2px !important;
      right: -5px !important;
      padding-right: 5px !important;
      border-radius: .28571429rem !important;
    }
}



/* entferne Zoomlinks und Links über der Suche **/
@media only screen and (max-width: 820px) {
  .defaultGrid {
    grid-template-columns: 60px minmax(min-content,2fr) auto minmax(min-content,1.5fr) max-content minmax(0,10px);
    grid-template-areas:   "logo search pad zoomSlider zoomMode padRight";
  }

  #resetButton {
    display: none;
  }

  #pageLinks {
    display: none;
  }

  #altLinks {
    visibility: visible;
    top: auto;
    bottom: 0;
    width: auto;
  }


}

/* schließe Logo und klappe Zoomoptionen ein */
@media only screen and (max-width: 864px) {
  .defaultGrid {
    grid-template-columns: minmax(150px, 4fr) 10px minmax(min-content, 5fr) max-content minmax(0,10px);
    grid-template-areas:   "search pad zoomSlider zoomMode_small padRight";
  }  /* Zoomslider (4fr) ist geringfügig größer als Suchfeld (3fr) */

  #pageTitle {
    display: none;
  }

  #pageLinks {
    display: none;
  }

  #altLinks {
    visibility: visible;
    top: auto;
    bottom: 0;
    width: auto;
  }

  #searchCollapsed_title {
    display: flex;
  }

/*  Variante mit Rückknopf
  .searchGrid {
    grid-template-columns: 70% 40px;
    grid-template-areas: "search" "search_back";
  }
*/

  .container_search {
    grid-template-columns: 100% !important;    /* statt max-content, damit Suche auch wirklich einklappt */
  }

  .container_zoomMode_small {
    display: inline-block;
  }

  .container_zoomMode {
    display: none;
  }

  .container_zoomLinks {
    display: none;
  }


  /* Füge Labels für den ausgeklappten Zustand wieder hinzu */
  .buttonLabel {
      display: inline-block;
    }

  .ui.labeled.inverted.icon.customButton2 {
    padding-right:  15px !important;
    padding-bottom: 10px !important;
  }

  #zoomMode_btn {
    grid-area: zoomMode_btn;
    margin: 0 2.5px 0 2.5px;

    /* Grid layout */
    display: grid;
    grid-template-columns: max-content 40px;
    grid-template-rows: 100%;
    grid-template-areas:
      "zoomMode_btn_options"
      "zoomMode_btn_back";
    justify-content: stretch;
  }

  #zoomMode_btn_back {
    grid-area: "zoomMode_btn_back";
    visibility: visible;
  }

  #search_btn_back {
    grid-area: "search_back";
  }

  .logo {
    display: none;
  }
}




/* klappe Suche ein und verkleinere Schrift */
@media only screen and (max-width: 700px) {
  .defaultGrid {
    grid-template-columns: 50px 10px minmax(min-content,1fr) max-content minmax(0,10px);
    grid-template-areas:   "search pad zoomSlider zoomMode_small padRight";
  }


  .zoomRank_txt {
    font-size: 10.5px;
  }


}


/** Header: Elemente **/


.logo {
    width: auto;
    height: 100%;
}

.altLogo {
  width: auto;
  height: 1.3em;
  padding-top: 4px;
}





.container_pad {
    grid-area: pad;
    /* border: 1px solid white; */
}

.container_search {
  grid-area: search;
  /* border: 1px solid white; */

  /* Grid layout */
  display: grid;
  grid-template-columns: minmax(max-content, 100%);
  grid-template-rows: max-content max-content auto;
  grid-template-areas:
    "sub_logo"
    "sub_search"
    "sub_padding";
}

.container_searchCollapsed {
  grid-area: searchCollapsed;
}

.subContainer_title {
  grid-area: sub_logo;
  color: #DDD;
  font-weight: bold;
  padding-bottom: 2px;
  font-size: 20px;
  min-width: min-content;

  /* Flexbox */
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.customList {
  margin-left: 30px !important;
}

.customItem a {
  text-decoration: none;
  color: inherit; /* Link nicht farblich kennzeichnen */
}

.customItem:not(:last-child) {
  border-right: 1px solid var(--title_colour) !important;
}


#semantic_SearchContainer {
  /* border: 1px solid yellow; */
  grid-area: sub_search;
}

.phylum_wrapper {

}

.phylum_lbl {
  position: relative;
  bottom: 3px;
}

.foundAs {
  font-size: 12px;
  margin-top: 6px;
}


.zoomTitle {
  color: var(--title_colour);
  font-weight: bold;
  font-size: 12px;
  padding-left: 5px;
}





#zoomMode_title {
  grid-area: zoomMode_title;
}

/* #zoomMode_btn weiter oben (muss in Reihenfolge vor Media Querys erscheinen) */

#zoomMode_btn_options {
  grid-area: "zoomMode_btn_options";
}




#zoomLinks_title {
  grid-area: zoomLinks_title;
}

#zoomLinks_btn {
  grid-area: zoomLinks_btn;
  margin: 0 2.5px 0 2.5px;
}



.customIcon {
  position: absolute;
  left: 10px;
  top: 0;
  height: 100%;
  width: auto;
  padding-top: 2.5px;
  padding-right: 2.5px;
}

.customIcon2 {
  position: absolute;
  left: 10px;
  top: 0;
  height: 80%;
  width: auto;
  padding-top: 7px;
  padding-right: 2.5px;
}

.customGroup_autoZoomMode {
  height: 100%;
}

.customButton_autoZoomMode {
  width: 35px;
  padding-left: 12px !important;
  padding-right: 27px !important;
}

.customIcon_autoZoomMode {
  width: 30px;
  margin: -12px;
  padding: 3px;
}

.container_zoomSlider {
  grid-area: zoomSlider;
  /* border: 1px solid white; */

  /* Grid layout */
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: min-content 20px 20px auto;
  grid-template-areas:
    "zoomSlider_title"
    "zoomSlider_ranks"
    "zoomSlider_padding"
    "zoomSlider_padding2";
  padding: 0;
  justify-content: start;
  }

 #zoomSlider_title {
   grid-area: zoomSlider_title;
 }

 #zoomSlider_ranks {
   grid-area: zoomSlider_ranks;

 }

 #zoomSlider_padding {
   grid-area: zoomSlider_padding;
 }

.transparentOverlay {
  background-color: rgba(70,70,70,0.5);
  padding-bottom: 0;
  margin: 0 0 2px 0;
  /* border-radius: 5px; */
}

.container_zoomRanks {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.zoomRank_flex {
  display: inline-block;
  cursor: pointer;
  font-size: 13px;
  color: #99999;
  text-align: center;
  margin: 2px;
}

/** alle Radiobuttons verbergen */
input[type="radio"] {
  position: fixed;
  opacity: 0;       /** besser als display: none, weil damit die Navigation mit Pfeiltasten noch funktioniert **/
}                   /** position: fixed wird benötigt, damit das Element keinen Platz verbraucht**/
                    /** ohne position:fixed ist der Rahmen des Elements kurz zu sehen**/




/* könnte theoretisch eingefärbt werden
/* aber nicht so schlau, da es inzwischen über Semantic-UI-Klassen läuft
.zoomMode_lbl input:checked ~ .customRadio {
    background-color: red;
    color: red;
    border: 5px solid orange;
}
*/




.zoomRank_txt {
  padding: 0;
}

.zoomRank_txt_fixed {
  display: none;
}

.zoomRank_flex input:checked ~ .zoomRank_txt {
   color: var(--btn_colour_zoom);
   font-weight: bold;
   text-shadow: -2px 0 #222222, 0 2px #222222, 2px 0 #222222, 0 -2px #222222;
}

.zoomRank_flex input ~ .zoomRank_txt {
    color: var(--btn_inactive);
    font-weight: normal;
    text-shadow: -1px 0 #222222, 0 1px #222222, 1px 0 #222222, 0 -1px #222222;
  }


/*********************/
#flex_padLeft {
  flex-basis: 0;
  flex-shrink: 1;
  flex-grow: 1;
}

#flex_padRight {
  flex-basis: 20px;
  flex-shrink: 1;
  flex-grow: 1;
}

/*
#flex_phylum {
  flex-basis: 1%;
    flex-grow: 1;
}

#flex_class {
  flex-basis: 8%;
  flex-shrink: 10;
    flex-grow: 1;
}

#flex_order {
  flex-basis: 13%;
  flex-shrink: 1;
    flex-grow: 20;
}

#flex_family {
  flex-basis: 9%;
  flex-shrink: 1;
    flex-grow: 20;
}

#flex_genus {
  flex-basis: 21%;
  flex-shrink: 1;
    flex-grow: 20;
}

#flex_species {
  flex-basis: 30%;
    flex-grow: 20;
}
*/

/**********************/








/* KARTENBEREICH (Footer) */
.footer {
  position: relative; /* benötigt, damit Kind-Elemente (HUD) absolut im Footer positioniert werden können (statt absolute auf der gesamten Seite) */
  grid-area: footer;
  /* border: 2px solid orange; */
}





/*
.header {
  grid-area: header;
  border: 2px solid green;
}

.footer {
  grid-area: footer;
  border: 2px solid blue;
}
**/



/** ZOOM SLIDER    -> https://openlayers.org/en/latest/examples/zoomslider.html
 *
 * -> Die interaktive Funktionalität wird über setTarget in map.js diesem Div-Element zugeordnet. */

#zoomSlider .ol-zoomslider {
  position: relative;
  top: -1px;
  left: auto;
  width: auto;  /** 100%-margin**/
  height: 19px;
  margin: 0 20px 0 20px;
  padding: 0;
  background-color: rgba(0,0,0,0);
  border-radius: 19px;
  border: 2.5px solid #222222;
  z-index: 2;
    /* box-shadow: 0 0 5px rgb(255,69,0); **/
}

#zoomSlider .ol-zoomslider:hover {
  background-color: rgba(255,69,0,0.3);
}

#zoomSlider .ol-zoomslider-thumb {
  border: 5px solid #222222;
  top: -3px;
  margin-left: -5px;  /** Achtung: wenn das angepasst werden soll, muss auch general_offset in zoomSlider2.js angepasst werden */
  height: 20px;
  width: 20px;
  filter: none;
  background-color: var(--btn_colour_zoom) !important;
  border-radius: 20px;
  z-index: 3;
}



#zoomSlider a.ol-zoomslider-handle:hover {
  background-color: rgba(255,69,0,0.7);
}





/** Head-up display (HUD) **/

.hud {
  position: absolute;
  height: 200px;
  width: 50px;
  /* border: 1px solid red; */
  top: 5%;
  left: 0px;
}

.hud2 {
  position: absolute;
  top: 85px;
  left: 0px;
  /* border: 1px solid orange; */
  width: 100%;
  height: calc(100%-80px);

  /* Flexbox */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}

.customIcon3 {
  margin-left: 5px !important;
  margin-top: 5px !important;
}

/* HUD für Ansichten (P = Perspectives) */
.hudP {
  position: absolute;
  height: 200px;
  width: 200px;
  /* border: 1px solid red; */
  bottom: 3.5%;
  left: 0px;

  /* Flexbox */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 10px;
}



.hudP_general {
  position: absolute;
  top: 85px;
  left: 0px;
  /* border: 1px solid orange; */

  /* Flexbox */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}

.hudP_species {
  position: absolute;
  bottom: 140px;
  left: 0px;
  /* border: 1px solid orange; */

  /* Flexbox */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 10px;
}

.hudP_languages {
  position: relative;
  left: -5px;
  top: -20px;
  background-color: rgba(50,50,50,0.8);
  padding: 5px;
  border-radius: 4px;
  padding: 5px 5px 0px 2px;
  padding-right: 10px;
  width: fit-content;

  font-size: 11px;
  color: #f9fafb;
  margin: 0.5em;
}


.hud-margin-Pgeneral {
  /* width passt sich an Größe des Elternelements (hudP) an*/
  position: relative;
  left: -5px;
  background-color: rgba(50,50,50,0.8);
  border-radius: 4px;
  padding: 5px 5px 0px 2px;
  padding-right: 10px;
  width: fit-content;
}


.hud-margin-Pspecies {
  /* width passt sich an Größe des Elternelements (hudP) an*/
  position: relative;
  left: -5px;
  background-color: rgba(50,50,50,0.8);
  border-radius:4px;
  padding: 2px;
  width: 180px;
  display: none;
    /* standardmäßig deaktiviert, in ui.js sichtbar gemacht, wenn vorhanden */
}

.hud-margin-Pspecies-overflow {
  /* width passt sich an Größe des Elternelements (hudP) an*/
  width: 100%;
  height: fit-content;
  max-height: 210px;
  overflow-y: auto;
}

.buttonLabelP {
  font-size: 11px;
}

.Ptitle {
  font-size: 11px;
  color: #f9fafb;
  margin: 0.5em;
  text-align: center;

}

/** Zoombuttons **/
.ol-zoom .ol-zoom-in {
  background-color: rgba(50,50,50,0.8) !important;
  border-radius: 2px 2px 0 0;
  height: 35px;
  width: 35px;
  font-size: 20px;
  cursor: pointer;
  border-bottom: 1px solid #555555;
  left: -20px !important;
}
.ol-zoom .ol-zoom-out {
  background-color: rgba(50,50,50,0.8) !important;
  border-radius: 0 0 2px 2px;
  height: 35px;
  width: 35px;
  font-size: 20px;
  left: -20px !important;
  cursor: pointer;
}

/* Außenrand */
.ol-zoom {
  position: relative !important;
  left: -5px !important;
  top: -2px !important;
  width: 42px;
  background-color: rgba(50,50,50,0.8) !important;
}


/** äußerer Rand um alle weiteren Buttons (= äußerer Rand Zoombuttons) **/
.hud-margin {
  position: relative;
  left: -5px;
  background-color: rgba(50,50,50,0.8);
  width: 42px;
  border-radius:4px;
  padding: 2px;
  height: 40px;
}

.hud-button {
  /* Farben übernommen von OpenLayers */
    height: 100%;
    width: 100%;
    background-color: rgba(50,50,50,0.8) !important;
    border-radius:4px;
    padding:2px;
    cursor: pointer;
}

.hud-button:hover {
  background-color:rgba(255,255,255,.6);
}


/** Ebenenauswahl **/
#layer_options {
  position: absolute;
  top: 0;
  left: 4rem;
  display:inline-block;
  background-color:rgba(255, 255, 255, .8);
  width: 17rem;
  border-radius:4px;
  display: none;
  font-size: 12px;
  z-index: 5;
}

.layerOptions_title {
  display: block;
  padding-top: 5px;
  margin-left: 0.5rem;
  font-style: italic;
}

.customSegment {
  margin: 0 !important;
  padding: 1rem !important;
}

#layer_options input[type="checkbox"] {
  margin-left: 1.5rem;
}

.customButton {
  display: inline-block;
  cursor: pointer;
  margin-bottom: 5px;
}

.customButton:first-of-type {
  margin-left: 1.5rem;
}

.customButton2 {
  height: 97%;
}

.ui.labeled.icon.button.customButton4 {
  /* hohe Spezifizität, damit !important wirkt **/
  padding-right: 7px !important;
  height: 39px;
  /* [TODO] überarbeiten; 97% wie bei anderen Buttons funktioniert hier nicht */
}

/** CustomButton Perspective **/
.customButtonP {
  display: inline-block;
  cursor: pointer !important;
  margin-bottom: 5px;
  display: none;
}

.customButton2:first-of-type {
  margin-left: 0.5rem;
}

#btn_prsp_def {
  display: inline-block !important;
}

#btn_prsp_scheme {
  display: inline-block !important;
}






/** Suchergebnisse **/
.ui.search #semantic_SearchInput {
  width:  100%;
  height: 85%;
}

.ui.search .prompt {
  border-radius: 0.4rem !important;
}

.ui.category.search  {
    min-height: 3em !important;
}

.results {
  width: 100% !important;
  max-height: calc(100vh - var(--header_height) - 50px);
  overflow-y: auto;                            /* Scroll-Leiste nur bei Überfließen */
}

.result {
  min-height: 3em;
}

.name {
  vertical-align: top !important;
  padding-top: 0.75em !important;
}

.price {
  color: rgba(0,0,0,.87) !important;
}

.title {
  font-style: italic !important;
}

.ui.category.search > .results .category .result.active {
  background:  #e8f6f3 !important;
}

/** heißt in Semantic UI leider auch Header */
.message.empty .header {
  display: none;
}

/** Container für Text-Templates **/
.textTemplates {
  display: none;
}


/** Pop-Ups **/
.ol-popup {
     position: absolute;
     background-color: white;
     box-shadow: 0 1px 4px rgba(0,0,0,0.2);
     /* padding: 15px; */
     border-radius: 10px;
     border: 1px solid #cccccc;
     bottom: 12px;
     left: -50px;
     min-width: 400px;
     visibility: hidden;
   }
   .ol-popup:after, .ol-popup:before {
     top: 100%;
     border: solid transparent;
     content: " ";
     height: 0;
     width: 0;
     position: absolute;
     pointer-events: none;
   }
   .ol-popup:after {
     border-top-color: white;
     border-width: 10px;
     left: 48px;
     margin-left: -10px;
   }
   .ol-popup:before {
     border-top-color: #cccccc;
     border-width: 11px;
     left: 48px;
     margin-left: -11px;
   }
   .ol-popup-closer {
     text-decoration: none;
     position: absolute;
     top: 2px;
     right: 8px;
     cursor: pointer;
   }
   .ol-popup-closer:after {
     content: "✖";
   }


/* Popup */
.popup-content {
     /* Design */
     height: auto;
     width: 420px;
     /* border: 3px solid red; */

     /* Grid layout */
     display: grid;
     /* grid-gap: 5px; */
     grid-template-columns: 100%;
     grid-template-rows: minmax(3.5em, auto) minmax(1em, auto);
     grid-template-areas:
       "title"
       "phylo";
     padding-bottom: 10px;
   }

.popup-title {
  grid-area: title;
  background-color: #f3f4f5; /* übernommen aus der Suche */
  border-bottom: 2px solid #cccccc;
  font-size: 1.1em;
  font-family: Lato,Helvetica Neue,Arial,Helvetica,sans-serif;
  border-top-left-radius: 10px; /* an das Popup anpassen */
  border-top-right-radius: 10px;

  /* Grid layout */
  display: grid;
  grid-template-columns: 3fr minmax(auto, 4fr) minmax(auto, 4fr);
  grid-template-rows: 100%;
  grid-template-areas: "titleA titleB titleC";
  align-items: center;
}

#popup-titleA {
  border-right: 1px solid #cccccc;
  color: rgba(0,0,0,.4);    /* übernommen aus der Suche */
  text-align: center;
  font-weight: 700;
  height: 100% !important;

  /* Text vertikal zentrieren */
  display: flex;
  align-items: center;
  justify-content: center;
}

#popup-titleB {
  background-color: #e8f6f3;  /* übernommen aus Suche */
  font-style: italic;
  text-align: center;
  height: 100% !important;

  /* Text vertikal zentrieren */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#popup-titleB-black {
  display: inline-flex;
  font-weight: 700;
}

#popup-titleB-grey {
  display: inline-flex;
  color: gray;
  font-size: 12.5px;
  line-height: 1em;
}

#popup-titleC {
  background-color: #e8f6f3;  /* übernommen aus Suche */
  /* border-left: 1px solid #cccccc; */
  text-align: center;
  height: 100% !important;
  border-top-right-radius: 10px;
  padding-right: 15px;

  /* Text vertikal zentrieren */
  display: flex;
  align-items: center;
  justify-content: center;
}


/* Textgestaltung im Popup für Hilfe */
.help_container {
  width: 300px;
}

.helpLinks {
  text-align: left
}



/* Startansicht für Tabs */
#tab_1 #tab_2 #tab_3  {
  display: flex;
}

#tab_4 {
  display: none;
}

.popup-phylo {
  grid-area: phylo;
  width: 100%;
  height: auto;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 0px;
}

.customIcon4 {
  margin-right: 5px;
  margin-top: 1px;
}

.above_tree {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
  margin-top: -5px;
  margin-bottom: 5px;
  width: 100%;
  height: auto;
  font-size: 12px;
  font-family: Lato,Helvetica Neue,Arial,Helvetica,sans-serif;
  color: rgba(0,0,0,.4);    /* übernommen aus der Suche */
}


.tree {
  max-height: calc(50vh - var(--header_height) - 3.5em -50px);
  overflow-x: hidden;
  overflow-y: auto;
}

.below_tree {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 5px;
/*  border: 1px solid #cccccc */
}







.lit_container {
  font-size: 12px !important;
  line-height: normal !important;
  min-height: 60px;
  width: 500px;
}

.lit_container p, ul {
    padding-top: 0;
    margin-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    padding-left: 15px;
}

.cite {
  margin-top: 2px;
  text-align: end;

}

.buttonContainer {
  text-align: center;
}

.buttonGroup {
  display: inline-flex;
  border: 1px solid #cccccc;
}

.flexDivider {
    display: inline-flex;
    margin-left: 5px;
    margin-right: 5px;
}

.popup-footer {
  border-top: 2px solid #cccccc;
  height: 3em;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
}

#footer_imgSource {
  /* als Flexboxelement */
  visibility: visible;
  flex-grow: 5;
  flex-basis: 80%;
  transition: flex-grow 100ms linear;

  /* Texteigenschaften */
  line-height: 1.1 em;
  margin-left: 1em;       /* hängender Einzug */
  text-indent: -1em;      /* -> https://stackoverflow.com/a/17158366 */

  /* als eigene Flexbox */
  display: flex;
  align-items: center;    /* Text vertikal zentrieren */
  padding-left: 15px;
  padding-bottom: 2px;
}

#footer_permaLink {
  visibility: hidden;
  width: 100%;
  padding-left: 20px;
  margin-top: -4px;
  padding-right: 5px;
}


.footer_linkBtn {
  display: inline-flex;
  background-color: #f3f4f5; /* übernommen aus der Suche */
  font-family: Lato,Helvetica Neue,Arial,Helvetica,sans-serif;
  border-bottom-right-radius: 10px; /* an das Popup anpassen */

  border-left: 1px solid #cccccc;
  color: rgba(0,0,0,.4);    /* übernommen aus der Suche */
  padding-left: 15px;
  padding-right: 10px;
  padding-top: 8px;
  cursor: pointer;
  flex-grow: 1;
  flex-basis: 20%;
  width: 60px;
  transition: flex-grow 100ms linear;
}

#linkBtn {
  display: inline-flex;
  cursor: pointer;
  flex-grow: 1;
}

.flexRemove {
    /* -> https://stackoverflow.com/a/24976134 */
    flex-grow: 0.00001 !important;
    flex-basis: 0 !important;
    width: 0;
    margin-left: 0 !important;
    padding-left: 0 !important;
}

.flexBorder {
    border-bottom-left-radius: 10px; /* an das Popup anpassen */
    border-left: 0;
}


/** CSS-Pfeile **/

/* verändert von
  -> https://stackoverflow.com/a/29002095
  -> https://css-tricks.com/snippets/css/css-triangle/
*/
.customArrow {
  width: 0;
  height: 0;
  border-left: 11px solid transparent;
  border-right: 11px solid transparent;
  border-top: 11px solid rgba(34,36,38,.15);  /* Randfarbe */

  /* Positionierung des Pfeils */
  position: absolute;
  top: 0px;
  right: 20px;

}

.customArrow:after {
  content: '';
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-top: 12px solid white; /* Füllfarbe */

  /* Positionierung des Rands */
  position: absolute;
  left: -12px;
  bottom: 1px;
}

.invisible {
    display: none;
}


/* Taxonomische Kategorisierung */
#tab_taxo { /* Tab */
    margin-bottom: 1rem; /* wie die anderen Tabs (Semantic UI setzt für last-child sonst 0 ein) */
}

#taxonomy { /* mit Tabelle gefüllt */
  margin-bottom: 2px;
}
.taxonCategory_taxonLabel a {
  text-decoration: none;
  color: inherit; /* Link nicht farblich kennzeichnen */
}

.customSegment2 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.taxonCategory {
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
}

.taxonCategory_element {
  padding-top: 5px;
  padding-bottom: 5px;
}


.taxonCategory_rank {
   display: inline-flex;
   flex: 0 0 7em;
   height: 100%;
}

.taxonCategory_taxon {
   display: inline-flex;
   flex-grow: 1;
   border-left: 1px solid rgba(34,36,38,.15);
   padding-left: 7px;
   height: 100%;
}

.taxonomy_stats {
  display: flex;
  flex-direction: row;
  flex-wrap: no-wrap;
  font-size: 12px;
  justify-content: space-between;
  color: rgba(0,0,0,.4);
}

#stats_childrenContainer {
  display: inline-flex;
  flex-grow: 1;
  text-align: left;
  padding-left: 2px;
}

#stats_speciesContainer {
  display: inline-flex;
  text-align: right;
  padding-right: 2px;
}

#stats_childrenNo {
  padding-left: 0.5em;
}

#stats_speciesNo {
  padding-left: 0.5em;
}



/* Synonyme */
#tab_syno {
  font-size: 12px;
  max-height: calc(50vh - var(--header_height) -50px);
  overflow-x: auto;
  overflow-y: auto;
  margin-bottom: 1rem; /* wie die anderen Tabs (Semantic UI setzt für last-child sonst 0 ein) */
}


.syno_black {
  color: black;
}

.syno_gray {
  color: gray;
}


/* enthält Vorlagen für Taxonomietabelle und Synonyme */
.template_container {
  display: none;
}





/** Debug-Funktionen **/
.debugPanel {
  display: none;
}

.debug-text {
    background: rgba(20,20,20,0.8);
    width: 150px;
    font-size: 14px;
    padding-left: 5px;
    padding-right: 5px;
  }


  #mouse-position {
    width: auto;
    position: absolute;
    top: 10px;
    right: 50px;
    color: red;
    font-weight: bold;
    text-align: center;
    padding-left: 15px;
    padding-right: 15px;
  }

  #viewZoom_level {
    position: fixed;
    top: 80px;
    left: 50px;
    color: red;
    font-weight: bold;
  }

  #metaZoom_level {
    position: fixed;
    top: 100px;
    left: 50px;
    color: turquoise;
    font-weight: bold;
  }

  #gridZoom_level {
    position: fixed;
    top: 80px;
    left: 180px;
    color: orange;
    font-weight: bold;
  }

  #internalZoom_level {
    position: fixed;
    top: 100px;
    left: 180px;
    color: orange;
    font-weight: bold;
  }



/** Touren **/
#tourBox {
  position: absolute !important;
  bottom: 47px !important;
  right: 20px !important;
  width: 700px;
  min-height: 100px;
  /* border: 1px solid white; */
  padding: 5px;
  z-index: 500;
  display: none;
}

#tourPointTitle {
  font-size: 28px;
  padding-bottom: 5px;
}

#tourController {
  position: absolute !important;
  right: 20px   !important;
  bottom: 5px !important;
  height: 40px;
  width: 700px;
  padding-top: 5px;
  display: none;
}

.tourController_container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

#tour_counter {
  margin-top: 0.8px;
}

#tour_autoplay {
  width: 150px;
}

#tour_end {
  display: none;
  width: 150px;
}

/* Autoplay Icons */
#autoplay_play {

}

#autoplay_pause {
  display: none;
}

/** Testbereich für UI-Elemente **/
.absoluteBox {
  position: absolute;
  top: 100px;
  left: 70px;
  width: 700px;
  height: 220px;
  background-color: #FFF;
  border: 2px solid black;
  padding: 5px;
  z-index: 500;
  display: none;
}



/*
#sliderTest {
  border: 1px solid black;
  background:
      radial-gradient(20px 20px at 1% 50%, #552222 50%, transparent 50%),
      radial-gradient(20px 20px at calc(1.4%-12px) 50%, black 50%, transparent 50%),
      radial-gradient(20px 20px at calc(28.999999999999996%-12px) 50%, #AA2222 50%, transparent 50%),
      radial-gradient(20px 20px at calc(29.4%-12px) 50%, black 50%, transparent 50%),
      radial-gradient(20px 20px at calc(45%-12px) 50%, #225522 50%, transparent 50%),
      radial-gradient(20px 20px at calc(55.00000000000001% -12px) 50%, #22AA22 50%, transparent 50%),
      radial-gradient(20px 20px at calc(78%-12px) 50%, #222255 50%, transparent 50%),
      radial-gradient(20px 20px at calc(100%-12px) 50%, #2222AA 50%, transparent 50%),
      linear-gradient(to right, #552222 0%,#552222 1%,#AA2222 1%,#AA2222 28.999999999999996%,#225522 28.999999999999996%,#225522 45%,#22AA22 45%,#22AA22 55.00000000000001%,#222255 55.00000000000001%,#222255 78%,#2222AA 78%,#2222AA 100%);
                /*
              radial-gradient(20px 20px at 1% 50%, #552222 50%, transparent 50%),
              radial-gradient(20px 20px at 22% 50%, #AA2222 50%, transparent 50%),
              radial-gradient(20px 20px at 39% 50%, #225522 50%, transparent 50%),
              radial-gradient(20px 20px at 51% 50%, #22AA22 50%, transparent 50%),
              radial-gradient(20px 20px at 75% 50%, #222255 50%, transparent 50%),
              linear-gradient(to right, #552222 0%,#552222 1%,#AA2222 1%,#AA2222 22%,#225522 22%,#225522 39%,#22AA22 39%,#22AA22 51%,#222255 51%,#222255 75%,#2222AA 75%,#2222AA 100%);

    background-repeat: no-repeat;
    border-radius: 20px;
  */
